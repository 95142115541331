<template>
  <div class="addy-plus-properties-list">
    <Permission/>
    <Breadcrumb/>
    <div class="title">Properties List</div>
    <div class="subtitle">{{ corporationName }}</div>
    <Tabs/>
    <main>
      <b-table :data="properties" hoverable>
        <b-table-column field="name" label="Property Address" v-slot="props" header-class="is-fullwidth">
          <div class="has-text-primary is-clickable" @click="onPropertyClick(props.row.id)">{{ props.row.name }}</div>
        </b-table-column>
        <b-table-column field="name" label="Hash ID" v-slot="props" header-class="is-white-space-nowrap">{{ props.row.id }}</b-table-column>
      </b-table>
    </main>
  </div>
</template>
<script>
import pageTitleMixin from '@utils/mixins/addy-plus-page-title-mixin.js'
import corpDetailsMixin from '@utils/mixins/addy-plus-corporation-details-from-side-menu-mixin.js'
import Tabs from '@views/addy_plus/corporations/Tabs.vue'
import { mapState } from 'vuex'
import Permission from '@views/addy_plus/base/Permission.vue'
import Breadcrumb from '@components/breadcrumb/breadcrumb.vue'

export default {
  title: 'Properties',
  mixins: [pageTitleMixin, corpDetailsMixin],
  components: {
    Permission,
    Tabs,
    Breadcrumb,
  },
  methods: {
    onPropertyClick(propId) {
      this.$router.push(`/addyPlus/${this.accountId}/corporations/${this.corporationId}/properties/${propId}`)
    },
  },
  computed: {
    ...mapState('addyPlusBase', ['corporationsForSideMenu']),
    accountId() {
      return this.$route.params.accountId
    },
    corporationId() {
      return this.$route.params.corporationId
    },
    properties() {
      // could consider using an independent api endpoint to fetch the properties
      const corp = this.corporationsForSideMenu[this.corporationId]
      return corp ? corp.properties : []
    },
  },
}
</script>
<style lang="scss" scoped>
.addy-plus-properties-list {
  font-size: 16px;
}
</style>
